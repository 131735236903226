import * as React from "react";

function SvgIcoChisel(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" {...props}>
      <path
        className="ico-chisel_svg__st0"
        d="M41 42.8V15.2l-2.2-6.7L12 22.2v20.6H3.4v3.1h46.3v-3.1H41z"
      />
    </svg>
  );
}

export default SvgIcoChisel;
