import React from 'react';

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            active: false
        }
    }

    onMouseEnter = () => {
        this.setState({hover: true});
        if (this.props.onMouseEnter) this.props.onMouseEnter();
    }
    onMouseLeave = () => {
        this.setState({hover: false});
        if (this.props.onMouseLeave) this.props.onMouseLeave();
    }

    onClick = () => {
        if (!(this.props.disable)){
            if (this.props.onClick) {
                this.props.onClick();
            }
            if (this.props.onValueChanged) {
                this.props.onValueChanged(!(this.props.active));
            }
        }
    }

    render() {

        let classes = ['button'];

        if (this.state.hover) {
            classes.push('hover');
        }

        if (this.props.active) {
            classes.push('active');
        }
        classes.push(this.props.className);

        return (<div style={this.props.style} id={this.props.id} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={this.onClick}
                     className={classes.join(" ")}>{this.props.children}</div>)
    }
}

export default Button;
