import {useDrag} from 'react-use-gesture'
import React, {useEffect, useRef, useState} from "react";

const WheelWrapper = (props) => {
    const [dragInfo, setDragInfo] = useState({my: 0, down: false});
    const lastDown = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        console.log("wheel mounted");
        setTimeout(() => {
            setMounted(true);
        }, 1000);
    }, [])

    useEffect(() => {
        console.log("wheel unmounted");
        setMounted(false);
    }, [props.mounted])

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(({down, direction: [dirX, dirY], velocity: vel, delta: delta, movement: [mx, my]}) => {
        //console.log(my);
        console.log("Down:" + down);
        console.log(delta[1]);
        if (lastDown.current && !down) {
            console.log({down: false, dir: dirY, vel: dirY > 0 ? vel : -vel});
            setDragInfo({timestamp: Date.now(), my: 0, down: false, vel: dirY > 0 ? vel : -vel});
        } else {
            setDragInfo({timestamp: Date.now(), my: delta[1], down: down, vel: vel, dir: dirY});
        }
        lastDown.current = down;
    })

    function handleTransitionEnd(e) {
        e.persist();                 // see: https://reactjs.org/docs/events.html#event-pooling
        if (e.propertyName === 'opacity') {
            props.onTransitionEnd();
        }
        console.log("Transition: " + e.propertyName);
    }


    let slideIn = '300px';
    if (props.orientation == 'left') {
        slideIn = '-300px';
    }

    return (<div onTransitionEnd={handleTransitionEnd}
        //onTransitionEnd={props.handleTransitionEnd}
                 style={{
                     touchAction: 'none',
                     transition: 'all',
                     transitionDuration: '1s',
                     transform: "translate(" + (mounted ? "0px" : "300px") + ",0px)",
                     opacity: mounted ? 1 : 0, ...props.style
                 }} {...bind()}>
        {React.cloneElement(props.children, {dragInfo: dragInfo})}
    </div>)
}
export default WheelWrapper;