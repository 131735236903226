import React from "react";

class MountingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            style: this.props.styleUnmounted
        }
    }

    componentDidMount() {
        console.log("delay;"+this.props.delay);
        this.setState({show:true});
        setTimeout(this.mount,this.props.delay)
    }

    UNSAFE_componentWillReceiveProps(newProps) { // check for the mounted props
        if(!newProps.mounted)
        {
            this.setState({style:this.props.styleUnmounted}); // call outro animation when mounted prop is false
        } else {
            this.setState({ // remount the node when the mounted prop is true
                show: true
            })
            setTimeout(this.mount,this.props.delay) // call the into animation
        }
    }

    mount = () => {
        console.log("mounting visualy");
        this.setState({style: this.props.styleMounted});
    }

    transitionEnd = () => {
        if(!this.props.mounted){ // remove the node on transition end when the mounted prop is false
            this.setState({
                show: false
            })
            if (this.props.onUnmount) {
                this.props.onUnmount();
            }
        } else {
            if (this.props.onMount) {
                console.log("MountingComponent onMount");
                this.props.onMount();
            }
        }
    }

    getStyles = () => {
        return {transition: 'all',transitionDuration:this.props.duration,...this.state.style}
    }

    _render(){
        return false;
    }

    render(){
        if (this.state.show){
            return this._render();
        } else {
            return false;
        }
    }


    }
export default MountingComponent;