import * as React from "react";

function SvgIcoCart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.041}
      height={27.4}
      {...props}
    >
      <path
        d="M20.894 7.23h-5.543v-2.9A4.336 4.336 0 0011.021 0a4.335 4.335 0 00-4.33 4.33v2.9H1.147L0 27.4h22.041zM8.78 4.33a2.244 2.244 0 012.241-2.241 2.244 2.244 0 012.241 2.241v2.9H8.775zM2.211 25.307L3.12 9.314h3.571v2.835h2.084V9.314h4.483v2.835h2.089V9.314h3.57l.909 15.993z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgIcoCart;
