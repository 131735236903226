import React from "react";
import MountingComponent from "./MountingComponent";
import Wheel3 from "./Wheel3";
import classNames from "classnames";

class BodyContainer extends React.Component {

    handleAnimationEnd = (e) => {
        e.persist();                 // see: https://reactjs.org/docs/events.html#event-pooling
        if (e.animationName == 'tip-transition-pickright-home' || e.animationName == 'tip-transition-pickleft-home' || e.animationName == 'desktop-container-plug-left'|| e.animationName == 'desktop-container-plug-right') {
            this.props.onTransitionEnd(e);
        }
        console.log("Animation: " + e.animationName);
    }

    render() {
        console.log("from: "+this.props.transitionFrom);
        console.log(this.props.transitionFrom == 'pickRight');

        let bodyClass = classNames({
            'body-container': true,
            'container-home': this.props.mode == 'home',
            'container-pickright': this.props.mode == 'pickright',
            'container-pickleft': this.props.mode == 'pickleft',
            'from-right': this.props.transitionFrom == 'pickRight',
            'from-left': this.props.transitionFrom == 'pickLeft',
            'unplug-right': this.props.showRightAnimationClass ? this.props.plugStateRight=='unplug':false,
            'plug-right': this.props.showRightAnimationClass ? this.props.plugStateRight=='plug':false,
            'unplug-left': this.props.showLeftAnimationClass ? this.props.plugStateLeft=='unplug':false,
            'plug-left': this.props.showLeftAnimationClass ? this.props.plugStateLeft=='plug':false
        });
        let rightClass = classNames({
            'plug-tip':true,
            'tip-right': true
/*            'plug-right':  this.props.plugStateLeft==='plug',
            'unplug-right': this.props.plugStateLeft==='unplug'*/
        });
        let leftClass = classNames({
            'plug-tip': true,
            'tip-left': true
/*            'plug-left':  this.props.plugStateLeft==='plug',
            'unplug-left': this.props.plugStateLeft==='unplug'*/
        });


        let baseline = this.props.windowHeight / 2;
        let baseHeight = Wheel3.getBaseHeight();
        let baseWidth = Wheel3.getBaseWidth();
        let wheelWidth = Wheel3.getMaxWidth("right");
        let windowWidth = this.props.windowHeight;

        let centerWheel = (windowWidth - baseWidth) / 2 - (wheelWidth - baseWidth);
        let bodyLeft = (windowWidth - baseWidth) / 2 - 10 - (580 / (130 / baseHeight)) - 1;
        let rightCardrigeLeft = (253 / (130 / baseHeight));
        let leftCardrigeLeft = (-131 / (130 / baseHeight));

        return (<div onAnimationEnd={this.handleAnimationEnd} className={bodyClass} key={"body-inner"}
                     style={{position: "absolute", left: this.props.left, top: this.props.top}}>
                <div style={{
                    touchAction: 'none',
                    opacity: this.props.hideLeftCardridge ? 0 : 1,
                    transition: this.props.hideLeftCardridge ? "opacity 0.5s ease 0s" : "",
                    position: "absolute",
                    left: leftCardrigeLeft,
                    top: "0px",
                    zIndex: -1,
                    height: baseHeight,
                    width: baseWidth
                }} className={leftClass}
                     src={this.props.srcLeft}/>

                <div style={{
                    touchAction: 'none',
                    opacity: this.props.hideRightCardridge ? 0 : 1,
                    transition: this.props.hideRightCardridge ? "opacity 0.5s ease 0s" : "",
                    position: "absolute",
                    left: rightCardrigeLeft,
                    top: "0px",
                    zIndex: -1,
                    height: baseHeight,
                    width: baseWidth
                }} className={rightClass}
                     src={this.props.srcRight}/>
                <img
                    height={baseHeight} id={"sketcher-body"} src={'static/media/all/Koerper.png'}/>
            </div>
        );
    }
}

export default BodyContainer;