import React from 'react';
import IcoConfig from '../icons/IcoConfig';
import Button from "./Button";
import IcoArrowUp from "../icons/IcoArrowUp";
import IcoArrowDown from "../icons/IcoArrowDown";
import IcoArrow2 from "../icons/IcoArrow2";
import IcoChisel from "../icons/IcoChisel";
import IcoRound from "../icons/IcoRound";
import IcoBrush from "../icons/IcoBrush";
import IcoCart from "../icons/IcoCart";
import IcoClear from "../icons/IcoClear";
import classNames from "classnames";

class SvgButton extends Button {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        let classes = classNames('button','svgButton', this.props.className,{
            'hover':this.state.hover,
            'active':this.props.active,
            'disabled':this.props.disable,
        });


        let svg;
        switch (this.props.icon){
            case "config":
                svg = (<IcoConfig></IcoConfig>);
                break;
            case "arrow-up":
                svg = (<IcoArrowUp></IcoArrowUp>);
                break;
            case "arrow-down":
                svg = (<IcoArrowDown></IcoArrowDown>);
                break;
            case "arrow2":
                svg = (<IcoArrow2></IcoArrow2>);
                break;
            case "brush":
                svg = (<IcoBrush></IcoBrush>);
                break;
            case "round":
                svg = (<IcoRound></IcoRound>);
                break;
            case "chisel":
                svg = (<IcoChisel></IcoChisel>);
                break;
            case "cart":
                svg = (<IcoCart></IcoCart>);
                break;
            case "clear":
                svg = (<IcoClear></IcoClear>);
                break;
        }

        const TagName = this.props.icon;
        return (<div id={this.props.id} style={this.props.style} onClick={this.onClick} className={classes} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} >
            {this.props.position=="right" && this.props.children}
            {svg}
            {this.props.position=="left" && this.props.children}


        </div> )
    }
}

export default SvgButton;
