import * as React from "react";

function SvgIcoClear(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.1 14.1" {...props}>
      <path
        className="ico-clear_svg__st0"
        d="M14.1 12.5L8.6 7l5.3-5.3L12.5.3 7.2 5.6 1.6 0 .2 1.4 5.8 7 0 12.7l1.4 1.4 5.8-5.7 5.5 5.5z"
      />
    </svg>
  );
}

export default SvgIcoClear;
