import React from 'react';

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: 0
        }
    }

    render() {
        return (<div style={{backgroundColor: this.props.data.color}} className={'item'}>{this.props.data.id}</div> )
    }
}

export default Item;
