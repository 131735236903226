import React, {useRef, useState} from 'react';
import {animated} from "react-spring";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile, withOrientationChange, isDesktop
} from "react-device-detect";

let windowWidth = window.innerWidth; //document.getElementById('root').offsetWidth;
let windowHeight = window.innerHeight;//document.getElementById('root').offsetHeight;
if (isDesktop){
    if (windowWidth / windowHeight < 1.85) {
        windowHeight = windowWidth / 1.85;
    }
}

let containerHeight = windowHeight;
let border = 60;
let elementVisible = 16;
let viewportHeight = containerHeight-border*2;
let viewportAngle = 170;
let elementCount = 120;
let factor = 3.54;
let elementHeight = viewportHeight / (elementVisible);
let d = viewportHeight/(Math.sin(viewportAngle/2*(Math.PI/180))*2)*2;
let r = d/2;

class Wheel3 extends React.Component {

    static tips = [];
    static colors = [];


    constructor(props) {
        super(props);
        let initPos = (elementCount + props.initPosition - elementVisible/2)%elementCount;

        elementCount = Wheel3.colors.length;

        let logicalPos =  ((initPos)*elementHeight)+elementHeight/2;

        let pos = logicalPos % viewportHeight;
        let positionMap = [];
        //debugger;
        for (let i=0;i<elementVisible;i++){

            positionMap[i] = elementVisible-Math.ceil((Wheel3.calculateY(i,pos)+viewportHeight/2)/elementHeight);
        }
        this.state = {
            logicalIndex: initPos,
            logicalPos: logicalPos,
            pos: pos,
            positionMap: positionMap,
            timerRunning: false,
            wheelPower: 0,
            wheelFactor: 0,
            wheelDirection: false,
            timerCounter: 50,
            startSpindown: 0,
            mode: 'plug',
            moveWheelTimestamp: 0,
            timestamp:0
        }
        //debugger;
        this.timer = setInterval(() => {
            if (this.state.timerRunning == true){

                if (this.state.timerCounter>0) {

                    let power = (1 * Math.pow(this.state.wheelFactor,this.state.timerCounter))-(1 * Math.pow(this.state.wheelFactor,this.state.timerCounter-1));
                    //let power = this.state.wheelPower/1.1;
                    if (this.state.wheelDirection){
                        let data = Wheel3.updatePosition(power,this.state);
                        data.timerRunning=true;
                        data.timerCounter=this.state.timerCounter-1;
                        this.setState(data);
                    } else {
                        let data = Wheel3.updatePosition(-power,this.state);
                        data.timerRunning=true;
                        data.timerCounter=this.state.timerCounter-1;
                        this.setState(data);
                    }
                } else {
                    this.setState({timerRunning:false});

                    let realIndex = (elementCount + this.state.logicalIndex + elementVisible/2)%elementCount;

                    let wheelColor = Wheel3.colors[realIndex];
                    this.props.valueChange({name:wheelColor[this.props.language],code:wheelColor.code,index:realIndex});
                    if (this.props.onEndMove) this.props.onEndMove();
                    //setSpinning(false);
                }
            }

        }, 33);
    }

    static calculateOpacity(pos) {

        //return 1;
        let phi = Math.abs((pos+elementHeight/2) % viewportHeight / viewportHeight) * Math.PI;

        return 1-Math.sin(phi);
    }

    static relativeToAbsoluteY(ry){

        //return ry+viewportHeight/2;
        let b = ry;
        //let alpha = b/(r*2*Math.PI)*360;
        //let realtiveDist = Math.sin(alpha*(Math.PI/180))*r;

        // Kreisbogenlänge

        let alpha = b /(viewportHeight/viewportAngle);
        //let alpha = b/(r*2*Math.PI)*360;
        let realtiveDist = Math.sin(alpha*(Math.PI/180))*r;
        //let alpha = b/r;
        //let realtiveDist = Math.sin(alpha)*r;

        return realtiveDist+viewportHeight/2;
    }

    static calculateY(index,dist = 0){

        //debugger;
        let elementDist = index * elementHeight;

        let pos = (elementDist+dist) %  (viewportHeight);

        if (pos < 0) {
            pos = pos + (viewportHeight);
        }

        return pos - viewportHeight/2;
        // eine Elementhöhe nach unten verschieben um Abstand zum Rand zu bekommen
        //return relativeToAbsoluteY(pos-viewportHeight/2);
    }

    static getDerivedStateFromProps(props, state) {
        // Any time the current user changes,
        // Reset any parts of state that are tied to that user.
        // In this simple example, that's just the email.

        //console.log(props);
        //console.log(state);
        /*if (props.userID !== state.prevPropsUserID) {
            return {
                prevPropsUserID: props.userID,
                email: props.defaultEmail
            };
        }*/
        if (props.move.timestamp!=state.moveWheelTimestamp){

            let start = 1;
            let ziel = props.move.steps*elementHeight;
            let steps = 5;//*(props.dragInfo.vel*0.2);
            let direction = ziel>0;

            ziel = Math.abs(ziel)+1;
            let zinssatz = Math.pow(ziel/start,1/steps);

//                console.log("Ziel: "+ziel+" Zinssatz:"+zinssatz+" EH:"+elementHeight);
//                console.log(state.pos+"+500*"+props.dragInfo.vel+"/"+elementHeight+")*"+elementHeight+"-"+(elementHeight/2)+"-"+state.pos);

            return {timerCounter:steps, wheelPower:ziel-1, wheelFactor:zinssatz, wheelDirection: direction,timerRunning:true,moveWheelTimestamp:props.move.timestamp};
        }

        if (props.dragInfo.timestamp!=state.timestamp){
            let dist = props.dragInfo.my;
            let down = props.dragInfo.down;

            if (state.lastDown && down==false){
                // Nachlauf starten
                let start = 1;
                let ziel = Math.round((state.pos+500*props.dragInfo.vel)/elementHeight)*elementHeight-elementHeight/2-state.pos;
                let steps = 20;//*(props.dragInfo.vel*0.2);
                let direction = ziel>0;

                ziel = Math.abs(ziel)+1;
                let zinssatz = Math.pow(ziel/start,1/steps);

//                console.log("Ziel: "+ziel+" Zinssatz:"+zinssatz+" EH:"+elementHeight);
//                console.log(state.pos+"+500*"+props.dragInfo.vel+"/"+elementHeight+")*"+elementHeight+"-"+(elementHeight/2)+"-"+state.pos);

                return {timerCounter:steps, wheelPower:ziel-1, wheelFactor:zinssatz, wheelDirection: direction,timerRunning:true, lastDown:false,lastTimestamp:props.dragInfo.timestamp};
            }

            if (dist!=0){
                let updateData = Wheel3.updatePosition(dist,state);
                updateData.lastDown = true;
                updateData.timestamp = props.dragInfo.timestamp;

                return updateData;
            }
        }

        return null;
    }

    componentDidMount() {
        setTimeout(()=>{
            this.setState({mode:'normal'});
        },2000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.startSpindown){
            this.setState({startSpindown:0});
        }
    }


    static updatePosition(dist,state){

        let positionMap = [];
        let tempPos = 0;
        let tempLogicalIndex = 0;
        let logicalIndex = 0;
        let tempLogicalPos = 0;

        tempLogicalPos = (state.logicalPos + dist) % (elementCount*elementHeight);
        tempPos = tempLogicalPos % viewportHeight;
        if (tempPos<0) {
            tempPos = tempPos+viewportHeight;
        }

        tempLogicalIndex = Math.round((tempLogicalPos-elementHeight/2)/elementHeight);

        //debugger;
        tempLogicalIndex = tempLogicalIndex % (elementCount);

        if (tempLogicalIndex<0) {
            tempLogicalIndex = tempLogicalIndex + elementCount;
        }

        for (let i=0;i<elementVisible;i++){
            positionMap[i] = elementVisible-Math.ceil((Wheel3.calculateY(i,state.pos+dist)+viewportHeight/2)/elementHeight);
        }

        let data = {positionMap:positionMap,pos:tempPos,logicalIndex: tempLogicalIndex,logicalPos: tempLogicalPos,selected: Wheel3.colors[tempLogicalIndex].code};
        return data;
    }

    nachlauf = () => {
        let start = 1;
        let ziel = 500;
        let steps = 5;
        let zinssatz = Math.pow(ziel/start,1/steps);


        this.setState({timerCounter: steps,wheelFactor:zinssatz,timerRunning:true});
    }

    static getMinBaseHeight(){
        return (Wheel3.relativeToAbsoluteY(Wheel3.calculateY(elementVisible-1,elementHeight/2))-Wheel3.relativeToAbsoluteY(Wheel3.calculateY(elementVisible-2,elementHeight/2)));
    }


    static getBaseHeight(){
        return (Wheel3.relativeToAbsoluteY(Wheel3.calculateY(elementVisible/2,elementHeight/2))-Wheel3.relativeToAbsoluteY(Wheel3.calculateY(elementVisible/2-1,elementHeight/2)));
    }

    static getBaseWidth(){
        return Wheel3.getBaseHeight()*factor;
    }
    static getMinBaseWidth(){
        return Wheel3.getMinBaseHeight()*factor;
    }

    static getCardridgeLeft(orientation){
        let baseWidth = Wheel3.getBaseWidth();

        let left = 0;//(baseWidth-width)
        if (orientation == 'right') {
            left = (baseWidth-Wheel3.getMinBaseWidth())/2;//(baseWidth-width)
        }
        return left;
    }

    static getMaxWidth(orientation){
        const baseWidth = Wheel3.getBaseWidth();
        const minBaseWidth = Wheel3.getMinBaseWidth();
        if (orientation == 'right') {
            let value = (baseWidth-minBaseWidth)/2-(baseWidth-baseWidth)/2+baseWidth;//(baseWidth-width)
            //debugger;
            return value;
        }
        return  baseWidth-baseWidth+(baseWidth-baseWidth)/2+baseWidth;//(baseWidth-width)

    }

    render() {

        //debugger;
        containerHeight = this.props.height;
        viewportHeight = containerHeight-border*2;

        elementHeight = viewportHeight / (elementVisible);
        d = viewportHeight/(Math.sin(viewportAngle/2*(Math.PI/180))*2)*2;
        r = d/2;

        let items = [];
        let visible = true;
        // breite eines Elements genau in der Mitte des Rades
        const baseWidth = Wheel3.getBaseHeight()*factor;
        const minBaseWidth = Wheel3.getMinBaseWidth();
        for (let i=0;i<elementVisible;i++) {

            visible = true;
            let origY = Wheel3.relativeToAbsoluteY(Wheel3.calculateY(i,this.state.pos));
            let nextY = Wheel3.relativeToAbsoluteY(Wheel3.calculateY(i,this.state.pos+elementHeight));

            if (isNaN(origY)){
                return (<></>);
                debugger;
            }
            if (nextY<origY){
                visible = false;
            }
            if (this.props.mode=='plug') {
                if (this.state.positionMap[i]==Math.floor(elementVisible/2)) {
                    visible = false;
                }
            }

            let height = Math.abs(nextY-origY);
            let width = height*factor;

            let left = baseWidth-width+(baseWidth-width)/2;//(baseWidth-width)
            if (this.props.orientation == 'right') {
                left = (baseWidth-minBaseWidth)/2-(baseWidth-width)/2;//(baseWidth-width)
                //debugger;
            }
            //debugger;

            let blur = (0.8-Wheel3.calculateOpacity(Wheel3.calculateY(i,this.state.pos)))*0.1;
            if (blur < 0){
                blur = 0;
            }
            let styles = {left: left,top:origY+border,height: height, width:width,display: visible ? 'block':'none',background: `url('${'static/media/all/'+Wheel3.tips[this.props.tip]+'_'+(Wheel3.colors[(this.state.logicalIndex+this.state.positionMap[i]) % elementCount].code+(this.props.orientation=='right' ? '_re': '_li')+'.png')}') 0% 0% / 100% 100%`, opacity: Wheel3.calculateOpacity(Wheel3.calculateY(i,this.state.pos))};//,filter:'blur('+blur+'rem)' };

            items.push(<div className={'pen'} style={styles} key={i}></div>)
        }




        return (
            <div id={this.props.id} style={this.props.style} className={this.props.mode=='plug3' ? 'hide': ''}>
            <div style={{touchAction: 'none'}}>{items}</div>
            </div>
        )
    }
}

export default Wheel3;
