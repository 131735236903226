import React from "react";
import MountingComponent from "./MountingComponent";

class TipInfo extends MountingComponent {

    _render(){
        return (<div className={this.props.className} style={this.getStyles()} onTransitionEnd={this.transitionEnd}>{this.props.children}</div>);
    }
}

export default TipInfo;