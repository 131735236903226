import * as React from "react";

function SvgIcoArrow2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.795}
      height={11.795}
      {...props}
    >
      <path d="M3.412 0v1.738h5.416L0 10.566l1.229 1.229 8.828-8.828v5.416h1.738V0z" />
    </svg>
  );
}

export default SvgIcoArrow2;
