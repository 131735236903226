import './App.scss';
import React from 'react';
import Item from "./components/Item";
import Wheel from "./components/Wheel";
import Wheel2 from "./components/Wheel2";
import Button from "./components/Button";
import Wheel3 from "./components/Wheel3";
import WheelWrapper from "./components/WheelWrapper";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile, withOrientationChange, isDesktop
} from "react-device-detect";
import IcoConfig from "./icons/IcoConfig";
import SvgButton from "./components/SvgButton";
import HeaderFooter from "./components/HeaderFooter";
import TipInfo from "./components/TipInfo";
import BodyContainer from "./components/BodyContainer";
import logo from './molotow-logo.png';
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from "./components/RadialSeparators";
import DesktopTipControl from "./components/DesktopTipControl";


var tips = ['Brush', 'Chisel', 'Round'];
var colors = [];//'B230', 'B235', 'B240', 'B245', 'B250', 'B255', 'B260', 'B265', 'B270', 'B275', 'B280', 'B285', 'B290', 'BE440', 'BE445', 'BE450', 'BE455', 'BL600', 'BR460', 'BR465', 'BR470', 'BR475', 'BR480', 'CG510', 'CG515', 'CG520', 'CG525', 'CG530', 'CG535', 'G345', 'G350', 'G355', 'G360', 'G365', 'GB295', 'GB300', 'GB305', 'GB310', 'GB315', 'GG485', 'GG490', 'GG495', 'GG500', 'GG505', 'K400', 'K405', 'K410', 'K415', 'K420', 'K425', 'K430', 'K435', 'M120', 'M125', 'M130', 'M135', 'NG540', 'NG545', 'NG550', 'NG555', 'NG560', 'NG565', 'O035', 'O040', 'O045', 'O050', 'O055', 'O060', 'P140', 'P145', 'P150', 'P155', 'P160', 'P165', 'P170', 'P175', 'PL180', 'PL185', 'PL190', 'PL195', 'PL200', 'PL205', 'R065', 'R070', 'R075', 'R080', 'R085', 'R090', 'R095', 'R100', 'R105', 'R110', 'R115', 'T320', 'T325', 'T330', 'T335', 'T340', 'V210', 'V215', 'V220', 'V225', 'WG570', 'WG575', 'WG580', 'WG585', 'WG590', 'WG595', 'Y005', 'Y010', 'Y015', 'Y020', 'Y025', 'Y030', 'YG370', 'YG375', 'YG380', 'YG385', 'YG390', 'YG395'];
var config = null;

var faceArray = null;
var faceArray2 = null;




/*
Statushandling

gotoMode(MODUS)->
    1. Modus bleibt gleich, transition wechselt auf "leave"
        Seitenspezifische Animationen zum Verlassen werden abgespielt, Steuerlemente werden entfernt
    2. Modus wechselt auf Zielmodus, transition wechselt auf "enter"
        Seitenspezifische Animationen zum Betreten werden abgespielt, Steuerlemente werden hinzugefügt
    3. transition wechsel auf "none"


 */


class App extends React.Component {

    constructor() {
        super();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let lang = urlParams.get('lang');

        if (lang!='de' && lang!='en' && lang!='fr'){
            lang = 'de';
        }

        this.preloadCounter = 0;
        this.state = {
            config: null,
            preload: 0,
            tipRight: 0,
            tipLeft: 0,
            tipRightColor: 'P170',//colors[0],
            tipLeftColor: 'G345',//colors[0],
            tipLeftColorName: 'minze',
            tipRightColorName: 'limettensaft',
            indexRight: 0,
            indexLeft: 0,
            mode: "home",
            animation: "none",
            mounted: true,
            rightPlugged: false,
            leftPlugged: false,
            hideRightCardridge: true,
            hideLeftCardridge: true,
            disableButtons: true,
            transition: 'none', // none, leave, enter
            transitionSource: 'home',
            transitionTarget: 'home',
            debug: "",
            moveWheelRight: {timestamp: 0, steps: 0},
            moveWheelLeft: {timestamp: 0, steps: 0},
            desktopEditRight: true,
            desktopEditLeft: true,
            desktopRightAnimationClass:false,
            desktopLeftAnimationClass:false,
            resizeTimestamp:0,
            hideWheels: true,
            language: lang,
            shopToolTip: false,
            deliveryInfo: (lang === 'de' ? "*Personalisierter SKETCHER wird in Einzelteilen geliefert" : "*Personalized SKETCHER is delivered in separate parts")
        }

        // Config JSON einlesen


        var oReq = new XMLHttpRequest();
        let _this = this;
        oReq.onload = function (e){
            _this.initData(JSON.parse(this.responseText));
        };
        oReq.open("get", "/static/sketcher.json", true);
        oReq.send();

    }

    initData(data){
        this.setState({config:data.config,
            indexRight:data.config.rightId,
            indexLeft:data.config.leftId,
            tipRightColor:data.colors[data.config.rightId].code,
            tipLeftColor:data.colors[data.config.leftId].code,
            tipLeftColorName:data.colors[data.config.leftId][this.state.language],
            tipRightColorName: data.colors[data.config.rightId][this.state.language],
        });
        tips = data.tips;
        colors = data.colors;

        faceArray = tips.flatMap((tip) => {
            return colors.map((color) => {
                return tip + '_' + color.code + '_re.png';
            })
        });

        faceArray2 = tips.flatMap((tip) => {
            return colors.map((color) => {
                return tip + '_' + color.code + '_re_2.png';
            })
        });


        //preloading images
        faceArray.forEach((face) => {
            const img = new Image();
            img.onload = this.imagePreloaded;
            img.src = 'static/media/all/' + face;
        });
        faceArray2.forEach((face) => {
            const img = new Image();
            img.onload = this.imagePreloaded;
            img.src = 'static/media/all/' + face;
        });

        const img = new Image();
        img.onload = this.imagePreloaded;
        img.src = 'static/media/all/Koerper.png';

        Wheel3.tips = tips;
        Wheel3.colors = colors;
        colors = colors;

    }

    windowResized = () => {
        console.log("window resized");
//        this.setState({"windowHeight": window.innerHeight, "windowWidth": window.innerWidth, resizeTimestamp: Date.now(),desktopEditLeft:false,desktopEditRight:false});
        if (this.state.animation!='none') {
            window.location.reload();
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResized);

        window.scrollTo(0, 1);
        if (isMobile) {
            this.setState({rightPlugged: true, leftPlugged: true, hideRightCardridge: false, hideLeftCardridge: false});
        } else {
            this.setState({desktopEditLeft: true, desktopEditRight: true});
        }

        setTimeout(() => {
            this.setState({disableButtons: false})
        }, 1000);
    }


    tipChange = (side, tip) => {
        switch (side) {
            case "left":
                this.setState({tipLeft: tip});
                document.documentElement.style.setProperty('--tip-left-img', 'url(/static/media/all/' + tips[tip] + '_' + this.state.tipLeftColor + '_li.png)');
                document.documentElement.style.setProperty('--tip-left-img-alt', 'url(/static/media/all/' + tips[tip] + '_' + this.state.tipLeftColor + '_li_2.png)');

                break;
            case "right":
                this.setState({tipRight: tip});
                document.documentElement.style.setProperty('--tip-right-img', 'url(/static/media/all/' + tips[tip] + '_' + this.state.tipRightColor + '_re.png)');
                document.documentElement.style.setProperty('--tip-right-img-alt', 'url(/static/media/all/' + tips[tip] + '_' + this.state.tipRightColor + '_re_2.png)');

                break;
        }
    }

    /*********
     * Handler für Button-Klicks
     */

    rightClick = () => {
        this.setState({orientation: "right", mounted: false});
    }

    handleSaveSelection = () => {
        console.log("handleSaveSelection");
        if (this.state.config.mode==2){
            this.showSelectHint();
        } else if (this.state.config.mode==3){
          if (this.state.indexRight !== -1 && this.state.indexLeft !== -1) {
            let sku1 = '75'+(this.state.tipLeft+1)+colors[this.state.indexLeft].sku;
            let sku2 = '75'+(this.state.tipRight+1)+colors[this.state.indexRight].sku;
            window.open(this.state.config.addToCartUrl+sku1+','+sku2
              ,'_blank');
          }
        } else {
            if (this.state.indexRight !== -1 && this.state.indexLeft !== -1) {
                window.open(this.state.config.addToCartUrl+'/add/left/'+
                    this.state.tipLeftColor+
                    '/right/'+
                    this.state.tipRightColor+
                    '/leftTip/'+
                    tips[this.state.tipLeft]+
                    '/rightTip/'+
                    tips[this.state.tipRight]
                    ,'_blank');
            } else {
                // noch keine Auswahl getroffen
                this.showSelectHint();
            }
        }

    }

    /***********
     * Servicefunktionen
     */
    imagePreloaded = () => {
        this.preloadCounter++;
        let preload = Math.round(this.preloadCounter / (faceArray.length + faceArray2.length + 1) * 100);
        if (preload % 5 == 0 && preload != this.state.preload) {
            this.setState({preload: preload});
        }
    }

    showSelectHint = () => {
        this.setState({selectHint: true});
        setTimeout(() => {
            this.setState({selectHint: false});
        }, 1000);
    }

    colorChangeLeft = (value) => {
        console.log("colorChange left");
        this.setState({tipLeftColor: value.code, tipLeftColorName: value.name, indexLeft: value.index});
        document.documentElement.style.setProperty('--tip-left-img', 'url(/static/media/all/' + tips[this.state.tipLeft] + '_' + this.state.tipLeftColor + '_li.png)');
        document.documentElement.style.setProperty('--tip-left-img-alt', 'url(/static/media/all/' + tips[this.state.tipLeft] + '_' + this.state.tipLeftColor + '_li_2.png)');
    }

    colorChangeRight = (value) => {
        console.log("colorChange right");
        this.setState({tipRightColor: value.code,tipRightColorName: value.name, indexRight: value.index});
        document.documentElement.style.setProperty('--tip-right-img', 'url(/static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re.png)');
        document.documentElement.style.setProperty('--tip-right-img-alt', 'url(/static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re_2.png)');
    }

    moveWheel(value) {
        console.log("move wheel:" + value);
        if (this.state.mode === 'pickRight') {
            this.setState({'moveWheelRight': {timestamp: Date.now(), steps: value}, disableButtons: true});
        }
        if (this.state.mode === 'pickLeft') {
            this.setState({'moveWheelLeft': {timestamp: Date.now(), steps: value}, disableButtons: true});
        }
    }

    moveWheelLeft(value) {
        console.log("move left wheel:" + value);
        this.setState({'moveWheelLeft': {timestamp: Date.now(), steps: value}, disableButtons: true});
    }

    moveWheelRight(value) {
        console.log("move right wheel:" + value);
        this.setState({'moveWheelRight': {timestamp: Date.now(), steps: value}, disableButtons: true});
    }



    debug = (value) => {
        this.setState({debug: value});
    }

    plugClick = () => {
        this.setState({mode: "plug"});
    }

    /**********
     * handler für Callbackes
     */

    onWheelMoveEnd = () => {
        this.setState({disableButtons: false});
    }

    editRightClick = () => {
        if (isDesktop) {
            console.log("editRightClickDesktop");
            this.setState({'desktopEditRight': true,
                'rightPlugged': false,
                'hideRightCardridge': false,
                desktopRightAnimationClass:true});
        } else {
            console.log("editRightClick");
            this.gotoMode('pickRight');
        }
    }

    editLeftClick = () => {
        if (isDesktop) {
            console.log("editLeftClickDesktop");
            this.setState({'desktopEditLeft': true,
                'leftPlugged': false,
                'hideLeftCardridge': false,
                desktopLeftAnimationClass:true
            });
        } else {
            console.log("editLeftClickMobile");
            this.gotoMode('pickLeft');
        }
    }

    rightDoneClick = () => {

        if (isDesktop) {
            console.log("rightDoneClickDesktop");
            this.setState({'desktopEditRight': false,
                'rightPlugged': true,
                'hideRightCardridge': false,
                desktopRightAnimationClass:true,
                moveWheelRight:{timestamp: 0, steps: 0},
            });
        } else {
            console.log("rightDoneClickMobile");
            this.gotoMode('home');
        }
    }

    leftDoneClick = () => {
        console.log("leftDoneClick");
        if (isDesktop) {
            this.setState({'desktopEditLeft': false,
                'leftPlugged': true,
                'hideLeftCardridge': false,
                desktopLeftAnimationClass:true,
                moveWheelLeft:{timestamp: 0, steps: 0},
            });
        } else {
            this.gotoMode('home');
        }
    }

    handleTransitionStart = (event) => {
        console.log('transition start');
        this.setState({disableButtons: true});
    }

    MobileFooterUnmounted = () => {
        // letzes Element der Animation, jetzt Seitenwechsel

//        this.setState({mode: 'home'});
//        this.setState({mounted: true});
    }

    componentsMountState = (mode) => {
        if (this.state.mode === mode) {
            if (this.state.transition === 'none') {
                // aktuell kein Übergang zu anderen Modus, alle Komponenten gemounted
                return true;
            } else if (this.state.transition === 'leave') {
                // Mode wird gerade verlassen, Komponenten ausblenden
                return false;
            } else {
                // Mode wird betreten, Komponenten einblenden
                return true;
            }
        }
    }

    leaveEnd = () => {
        console.log("leaveEnd");
        this.setState({
            mode: this.state.transitionTarget,
            transition: 'enter'
        });
        if (this.state.transitionTarget=='home'){
            setTimeout(() => {
                this.enterEnd();
            }, 1500);
        }
    }

    enterEnd = () => {
        console.log("enterEnd:" + this.state.mode);

        let state = {
            transition: 'none',
            disableButtons: false
        };

        switch (this.state.mode) {
            case 'home':
                break;
            case 'pickRight':
                state.hideRightCardridge = true;
                break;
            case 'pickLeft':
                state.hideLeftCardridge = true;
                break;
        }
        this.setState(state);
    }

    desktopLeftWheelVisible = () => {
        console.log("desktopLeftWheelVisible");
        this.setState({hideLeftCardridge:true});
    }

    desktopRightWheelVisible = () => {
        console.log("desktopRightWheelVisible");
        this.setState({hideRightCardridge:true});
    }

    desktopPlugDone = () => {
        this.setState({desktopRightAnimationClass:false,desktopLeftAnimationClass:false});
    }

    gotoMode = (target) => {
        console.log("gotoMode(" + target + ")");

        this.setState({
            transitionSource: this.state.mode,
            transitionTarget: target,
            transition: 'leave',
            disableButtons: true
        });

        switch (target) {
            case 'pickRight':
                //rightPlugged: true;
                this.setState({rightPlugged: true});
                setTimeout(() => {
                    console.log("pickRight leave end timer");
                    this.leaveEnd();
                }, 1000);
                break;
            case 'pickLeft':
                this.setState({leftPlugged: true});
                setTimeout(() => {
                    this.leaveEnd()
                }, 1000);
                break;
            case 'home':
                this.setState({rightPlugged: false, hideRightCardridge: false,moveWheelRight:{timestamp: 0, steps: 0},moveWheelLeft:{timestamp: 0, steps: 0},leftPlugged: false, hideLeftCardridge: false});
                setTimeout(() => {
                    this.leaveEnd()
                }, 1000);
                break;
        }
    }

    shopHover = (hover) => {
        this.setState({shopToolTip:hover});
    }

    renderDesktop() {
        if (!isDesktop) return;
        console.log("renderDesktop");

        let windowWidth = window.innerWidth; //document.getElementById('root').offsetWidth;
        let windowHeight = window.innerHeight;//document.getElementById('root').offsetHeight;
        if (windowWidth / windowHeight < 1.85) {
            windowHeight = windowWidth / 1.85;
        }

        let maxBaseHeight = windowWidth / 23.538461538461538;

        let baseline = windowHeight / 2;
        let baseHeight = Wheel3.getBaseHeight();
        let baseWidth = Wheel3.getBaseWidth();
        let wheelWidth = Wheel3.getMaxWidth("right");
        let bodyWidth = (580 / (130 / baseHeight));


        let centerWheelLeft = windowWidth / 2 - bodyWidth / 2 - wheelWidth + (wheelWidth - baseWidth);
        let centerWheelRight = windowWidth / 2 + bodyWidth / 2 - (wheelWidth - baseWidth);
        let bodyLeft = (windowWidth - baseWidth) / 2 - 10 - bodyWidth;
        let cardrigeLeft = (580 / (130 / baseHeight)) + 10;

        document.documentElement.style.setProperty('--baseWidth', (-(Wheel3.getBaseWidth() * 0.715 - 10)) + "px");

        document.documentElement.style.setProperty('--bodyWidth', bodyWidth + 'px');
        document.documentElement.style.setProperty('--baseHeight', baseHeight + "px");

        document.documentElement.style.setProperty('--buttonHeight', 82 / (130 / baseHeight) + "px");
        document.documentElement.style.setProperty('--tip-right-img', 'url(/static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re.png)');
        document.documentElement.style.setProperty('--tip-right-img-alt', 'url(/static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re_2.png)');
        document.documentElement.style.setProperty('--tip-left-img', 'url(/static/media/all/' + tips[this.state.tipLeft] + '_' + this.state.tipLeftColor + '_li.png)');
        document.documentElement.style.setProperty('--tip-left-img-alt', 'url(/static/media/all/' + tips[this.state.tipLeft] + '_' + this.state.tipLeftColor + '_li_2.png)');


        return (
            <>
                <HeaderFooter delay={10} duration={'1s'} mounted={this.componentsMountState('home')}
                              styleMounted={{transform: "translate(0px, 0px)"}}
                              onUnmount={this.MobileFooterUnmounted}
                    //onMount={this.enterEnd}
                              styleUnmounted={{transform: "translate(0px,-200px)"}} className={"header"}>
                    <a href={"https://yoursketcher.com/"} target={"_blank"}>
                        <img id="logo" src={logo}/><span id={"logo-text"}>Sketcher</span>
                    </a>

                </HeaderFooter>

                {this.state.desktopEditLeft &&
                <WheelWrapper onTransitionEnd={this.desktopLeftWheelVisible} mounted={this.state.desktopEditLeft}
                              orientation={'left'}
                              style={{
                                  left: centerWheelLeft,
                                  position: 'absolute',
                                  height: windowHeight,
                                  top: "50%",
                                  transform: "translate(0,-50%)"
                              }}
                >
                    <Wheel3 language={this.state.language} tips={tips} id={"wheel"} key="wheel" maxBaseHeight={maxBaseHeight} initPosition={this.state.indexLeft}
                            orientation={"left"} mode={!this.state.hideLeftCardridge ? 'plug' : 'normal'}
                            debug={this.debug} onEndMove={this.onWheelMoveEnd} height={windowHeight}
                            valueChange={this.colorChangeLeft} move={this.state.moveWheelLeft}
                            tip={this.state.tipLeft}
                    />
                </WheelWrapper>}
                {this.state.desktopEditRight &&
                <WheelWrapper onTransitionEnd={this.desktopRightWheelVisible} mounted={this.state.desktopEditRight}
                              orientation={'right'}
                              style={{
                                  left: centerWheelRight,
                                  position: 'absolute',
                                  height: windowHeight,
                                  top: "50%",
                                  transform: "translate(0,-50%)"
                              }}>
                    <Wheel3 language={this.state.language} id={"wheel"} key="wheel" maxBaseHeight={maxBaseHeight} initPosition={this.state.indexRight}
                            orientation={"right"} mode={!this.state.hideRightCardridge ? 'plug' : 'normal'}
                            debug={this.debug} onEndMove={this.onWheelMoveEnd} height={windowHeight}
                            valueChange={this.colorChangeRight} move={this.state.moveWheelRight}
                            tip={this.state.tipRight}/>
                </WheelWrapper>}

                <div className={"desktop-arrows-upper"}>
                    <SvgButton className={this.state.desktopEditLeft ? "visible":"hidden"} icon={"arrow-up"} disable={this.state.disableButtons} onClick={() => {
                        this.moveWheelLeft(1)
                    }}
                               id={"move-up-left"}/>
                    <SvgButton className={this.state.desktopEditRight ? "visible":"hidden"} icon={"arrow-up"} disable={this.state.disableButtons} onClick={() => {
                        this.moveWheelRight(1)
                    }} id={"move-up-right"}/>
                </div>
                <div className={"desktop-arrows-lower"}>

                    <SvgButton className={this.state.desktopEditLeft ? "visible":"hidden"} icon={"arrow-down"} disable={this.state.disableButtons} onClick={() => {
                        this.moveWheelLeft(-1)
                    }} id={"move-down-left"}/>
                    <SvgButton className={this.state.desktopEditRight ? "visible":"hidden"} icon={"arrow-down"} disable={this.state.disableButtons} onClick={() => {
                        this.moveWheelRight(-1)
                    }} id={"move-down-right"}/>
                </div>
                <BodyContainer onTransitionEnd={this.desktopPlugDone} key={"body"}
                               transitionFrom={this.state.transition === 'enter' ? this.state.transitionSource : ''}
                               mode={"desktop"} hideRightCardridge={this.state.hideRightCardridge}
                               hideLeftCardridge={this.state.hideLeftCardridge}
                               plugStateRight={this.state.rightPlugged ? 'plug' : 'unplug'}
                               plugStateLeft={this.state.leftPlugged ? 'plug' : 'unplug'}
                               showLeftAnimationClass={this.state.desktopLeftAnimationClass}
                               showRightAnimationClass={this.state.desktopRightAnimationClass}
                               windowHeight={windowHeight}
                               windowWidth={windowWidth}
                               srcRight={'static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re' + '.png'}/>


                <HeaderFooter delay={1000} duration={'1s'} mounted={true}
                              styleMounted={{transform: "translate(0px, 0px)"}}
                              onUnmount={this.MobileFooterUnmounted}
                              styleUnmounted={{transform: "translate(0px,100px)"}} className={"footer-bar"}>
                    <div className={"shop-tt"} style={{display:this.state.shopToolTip ? 'block':'none' }}>
                        coming soon...
                    </div>
                    {this.state.config.mode == 3 && <SvgButton disable={this.state.desktopEditLeft || this.state.desktopEditRight} icon={"arrow2"} id={"save"} position="right" onClick={this.handleSaveSelection}>{this.state.selectHint ? "select your colors and tips" : "SHOP"}</SvgButton>}
                    {this.state.config.mode == 2 && <SvgButton disable={true} icon={"arrow2"} id={"save"} position="right" onMouseEnter={() => {this.shopHover(true)}} onMouseLeave={() => {this.shopHover(false)}}  onClick={this.handleSaveSelection}>{"SHOP"}</SvgButton>}

                    <div className={"delivery-info"}>{this.state.deliveryInfo}</div>
                </HeaderFooter>

                <DesktopTipControl delay={1000} duration={'0.5s'} mounted={true}
                                   styleMounted={{ opacity:1}}
                                   styleUnmounted={{opacity:0}}
                                   className={"tip-control control-left"}
                                   disableButtons={this.state.disableButtons}
                                   doneClick={this.leftDoneClick}
                                   tipName={tips[this.state.tipLeft]}
                                   tip={this.state.tipLeft}
                                   tipColor={this.state.tipLeftColor}
                                   tipColorName={this.state.tipLeftColorName}
                                   tipChange={this.tipChange}
                                   orientation={'left'}
                                   edit={this.state.desktopEditLeft}
                                   editClick={this.editLeftClick}
                />
                <DesktopTipControl delay={1000} duration={'0.5s'} mounted={true}
                                   styleMounted={{ opacity:1}}
                                   styleUnmounted={{opacity:0}}
                                   className={"tip-control control-right"}
                                   disableButtons={this.state.disableButtons}
                                   doneClick={this.rightDoneClick}
                                   tipName={tips[this.state.tipRight]}
                                   tip={this.state.tipRight}
                                   tipColor={this.state.tipRightColor}
                                   tipColorName={this.state.tipRightColorName}
                                   tipChange={this.tipChange}
                                   orientation={'right'}
                                   edit={this.state.desktopEditRight}
                                   editClick={this.editRightClick}
                />
            </>);
    }

    renderMobile() {
        if (!isMobile) return;

        console.log("renderMobile");
        let windowHeight = window.innerHeight;//document.getElementById('root').offsetHeight;
        let baseline = windowHeight / 2;
        let baseHeight = Wheel3.getBaseHeight();
        let baseWidth = Wheel3.getBaseWidth();
        let wheelWidth = Wheel3.getMaxWidth("right");
        let windowWidth = window.innerWidth;

        let centerWheel = (windowWidth - baseWidth) / 2 - (wheelWidth - baseWidth);
        let cardrigeLeft = Wheel3.getCardridgeLeft('right');

        let bodyLeft = 0 - (580 / (130 / baseHeight) / 2) + (centerWheel + cardrigeLeft);//(windowWidth - baseWidth) / 2 - 10 - (580 / (130 / baseHeight)/2);

        let bodyWidth = (580 / (130 / baseHeight));


        document.documentElement.style.setProperty('--centerWheel', centerWheel + "px");
        document.documentElement.style.setProperty('--cardrigeLeft', cardrigeLeft + "px");


        document.documentElement.style.setProperty('--baseLine', baseline + "px");

        document.documentElement.style.setProperty('--baseHeight', baseHeight + "px");
        document.documentElement.style.setProperty('--baseWidth', (-(Wheel3.getBaseWidth() * 0.715 - 10)) + "px");
        document.documentElement.style.setProperty('--bodyLeft', bodyLeft + 'px');
        document.documentElement.style.setProperty('--bodyWidth', bodyWidth + 'px');

        let rightCardrigeLeft = (253 / (130 / baseHeight));
        let leftCardrigeLeft = (-131 / (130 / baseHeight));

        document.documentElement.style.setProperty('--rightCardrigeLeft', rightCardrigeLeft + 'px');
        document.documentElement.style.setProperty('--leftCardrigeLeft', leftCardrigeLeft + 'px');

        document.documentElement.style.setProperty('--tip-right-img', 'url(/static/media/all/' + tips[this.state.tipRight] + '_' + colors[this.state.indexRight].code + '_re.png)');
        document.documentElement.style.setProperty('--tip-right-img-alt', 'url(/static/media/all/' + tips[this.state.tipRight] + '_' + colors[this.state.indexRight].code+ '_re_2.png)');
        document.documentElement.style.setProperty('--tip-left-img', 'url(/static/media/all/' + tips[this.state.tipLeft] + '_' + colors[this.state.indexLeft].code + '_li.png)');
        document.documentElement.style.setProperty('--tip-left-img-alt', 'url(/static/media/all/' + tips[this.state.tipLeft] + '_' + colors[this.state.indexLeft].code  + '_li_2.png)');



        document.documentElement.style.setProperty('--buttonHeight', (window.innerWidth / 6 - 8) + "px");


        switch (this.state.mode) {
            case "home":
                return (
                    <>
                        <HeaderFooter delay={10} duration={'1s'} mounted={this.componentsMountState('home')}
                                      styleMounted={{transform: "translate(0px, 0px)"}}
                                      onUnmount={this.MobileFooterUnmounted}
                                      //onMount={this.enterEnd}
                                      styleUnmounted={{transform: "translate(0px,-200px)"}} className={"header"}>
                            <a href={"https://yoursketcher.com/"} target={"_blank"}>
                                <img id="logo" src={logo}/><span id={"logo-text"}>Sketcher</span>
                            </a>

                        </HeaderFooter>
                        <BodyContainer onTransitionEnd={this.leaveEnd} mode={"home"} key={"body"}
                                       transitionFrom={this.state.transition==='enter' ? this.state.transitionSource : ''}
                                       hideRightCardridge={this.state.hideRightCardridge}
                                       hideLeftCardridge={this.state.hideLeftCardridge}
                                       plugStateRight={'plug'}
                                       plugStateLeft={'plug'}
                                       //left={bodyLeft} top={baseline - baseHeight / 2}
                                       srcRight={'static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re' + '.png'}
                                       srcLeft={'static/media/all/' + tips[this.state.tipLeft] + '_' + this.state.tipLeftColor + '_le' + '.png'}
                        />

                        <SvgButton icon={"config"} disable={this.state.disableButtons} active={this.state.config.mode==3 && this.state.selectHint} onClick={this.editRightClick} className={"config-right"}/>
                        <SvgButton icon={"config"} disable={this.state.disableButtons} active={this.state.config.mode==3 && this.state.selectHint} onClick={this.editLeftClick}  className={"config-left"}/>

                        <HeaderFooter delay={1000} duration={'1s'} mounted={this.componentsMountState('home')}
                                      styleMounted={{opacity:100}}
                                      onUnmount={this.MobileFooterUnmounted}
                                      styleUnmounted={{opacity:0}} className={"footer-bar"}>
                            <div className={"shop-tt"} style={{display:this.state.shopToolTip ? 'block':'none' }}>
                                coming soon...
                            </div>
                            {this.state.config.mode == 3 && <SvgButton icon={"arrow2"} id={"save"} position="right" onClick={this.handleSaveSelection}>{this.state.selectHint ? "select your colors and tips" : "SHOP"}</SvgButton>}
                            {this.state.config.mode == 2 && <SvgButton icon={"arrow2"} id={"save"} position="right" onClick={this.handleSaveSelection}>{this.state.selectHint ? "coming soon..." : "SHOP"}</SvgButton>}
                            <div className={"delivery-info"}>{this.state.deliveryInfo}</div>
                        </HeaderFooter>
                    </>
                )
                break;
            case "pickLeft":
                return (
                    <>
                        <WheelWrapper onTransitionEnd={this.enterEnd} mounted={this.componentsMountState('pickLeft')}
                                      style={{left: centerWheel, position: 'absolute', height: window.innerHeight}}>
                            <Wheel3 language={this.state.language} id={"wheel"} key="wheel" initPosition={this.state.indexLeft}
                                    orientation={"right"} mode={!this.state.hideLeftCardridge ? 'plug' : 'normal'}
                                    debug={this.debug} onEndMove={this.onWheelMoveEnd} height={windowHeight}
                                    valueChange={this.colorChangeLeft} move={this.state.moveWheelLeft}
                                    tip={this.state.tipLeft}/>
                        </WheelWrapper>
                        <BodyContainer onTransitionEnd={this.leaveEnd} key={"body"}
                                       transitionFrom={this.state.transition === 'enter' ? this.state.transitionSource : ''}
                                       mode={"pickleft"} hideLeftCardridge={this.state.hideLeftCardridge}
                                       plugStateRight={this.state.rightPlugged ? 'plug' : 'unplug'}
                                       plugStateLeft={this.state.leftPlugged ? 'plug' : 'unplug'}
                            //left={bodyLeft} top={baseline - baseHeight / 2}
                                       srcRight={'static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_li' + '.png'}/>


                        <HeaderFooter delay={1000} duration={'1s'} mounted={this.componentsMountState('pickLeft')}
                                      styleMounted={{opacity:100}}
                                      onUnmount={this.MobileFooterUnmounted}
                                      styleUnmounted={{opacity:0}} className={"footer-bar"}>
                            <SvgButton icon={"round"} disable={this.state.disableButtons}
                                       active={this.state.tipLeft === 2} onClick={() => {
                                this.tipChange('left', 2)
                            }} className={'tipSelect'} id={"tip-round"}/>
                            <SvgButton icon={"chisel"} disable={this.state.disableButtons}
                                       active={this.state.tipLeft === 1} onClick={() => {
                                this.tipChange('left', 1)
                            }} className={'tipSelect'} id={"tip-chisel"}/>
                            <SvgButton icon={"brush"} disable={this.state.disableButtons}
                                       active={this.state.tipLeft === 0} onClick={() => {
                                this.tipChange('left', 0)
                            }} className={'tipSelect'} id={"tip-brush"}/>
                            <Button className={"large"} style={{float: "right"}}
                                    disable={this.state.disableButtons}
                                    onClick={this.leftDoneClick}>DONE</Button>
                        </HeaderFooter>
                        <TipInfo delay={1000} duration={'1s'} mounted={this.componentsMountState('pickLeft')}
                                 styleMounted={{transform: "translate(0px, -50%)"}}
                                 styleUnmounted={{transform: "translate(100px,-50%)"}} className={"tipInfo"}>
                            <SvgButton icon={"arrow-up"} disable={this.state.disableButtons} onClick={() => {
                                this.moveWheel(1)
                            }} id={"move-up"}/>
                            <div key={"tipcolor"} className={"tipcolor"}>{this.state.tipLeftColor}</div>
                            <div className={"tipColorName"}>{this.state.tipLeftColorName}</div>
                            <SvgButton icon={"arrow-down"} disable={this.state.disableButtons} onClick={() => {
                                this.moveWheel(-1)
                            }} id={"move-down"}/>

                        </TipInfo>
                    </>);
                break;
            case "pickRight":

                return (
                    <>
                        <WheelWrapper onTransitionEnd={this.enterEnd} mounted={this.componentsMountState('pickRight')}
                                      style={{left: centerWheel, position: 'absolute', height: window.innerHeight}}>
                            <Wheel3 language={this.state.language} id={"wheel"} key="wheel" initPosition={this.state.indexRight}
                                    orientation={"right"} mode={!this.state.hideRightCardridge ? 'plug' : 'normal'}
                                    debug={this.debug} onEndMove={this.onWheelMoveEnd} height={windowHeight}
                                    valueChange={this.colorChangeRight} move={this.state.moveWheelRight}
                                    tip={this.state.tipRight}/>
                        </WheelWrapper>
                        <BodyContainer onTransitionEnd={this.leaveEnd} key={"body"}
                                       transitionFrom={this.state.transition === 'enter' ? this.state.transitionSource : ''}
                                       mode={"pickright"} hideRightCardridge={this.state.hideRightCardridge}
                                       plugStateRight={this.state.rightPlugged ? 'plug' : 'unplug'}
                                       plugStateLeft={this.state.leftPlugged ? 'plug' : 'unplug'}
                            //top={baseline - baseHeight / 2} left={bodyLeft}
                                       srcRight={'static/media/all/' + tips[this.state.tipRight] + '_' + this.state.tipRightColor + '_re' + '.png'}/>


                        <HeaderFooter delay={1000} duration={'1s'} mounted={this.componentsMountState('pickRight')}
                                      styleMounted={{opacity:100}}
                                      onUnmount={this.MobileFooterUnmounted}
                                      styleUnmounted={{opacity:0}} className={"footer-bar"}>
                            <SvgButton icon={"round"} disable={this.state.disableButtons}
                                       active={this.state.tipRight === 2} onClick={() => {
                                this.tipChange('right', 2)
                            }} className={'tipSelect'} id={"tip-round"}/>
                            <SvgButton icon={"chisel"} disable={this.state.disableButtons}
                                       active={this.state.tipRight === 1} onClick={() => {
                                this.tipChange('right', 1)
                            }} className={'tipSelect'} id={"tip-chisel"}/>
                            <SvgButton icon={"brush"} disable={this.state.disableButtons}
                                       active={this.state.tipRight === 0} onClick={() => {
                                this.tipChange('right', 0)
                            }} className={'tipSelect'} id={"tip-brush"}/>
                            <Button className={"large"} style={{float: "right"}}
                                    disable={this.state.disableButtons}
                                    onClick={this.rightDoneClick}>DONE</Button>
                        </HeaderFooter>
                        <TipInfo delay={1000} duration={'1s'} mounted={this.componentsMountState('pickRight')}
                                 styleMounted={{transform: "translate(0px, -50%)"}}
                                 styleUnmounted={{transform: "translate(100px,-50%)"}} className={"tipInfo"}>
                            <SvgButton icon={"arrow-up"} disable={this.state.disableButtons} onClick={() => {
                                this.moveWheel(1)
                            }} id={"move-up"}/>
                            <div key={"tipcolor"} className={"tipcolor"}>{this.state.tipRightColor}</div>
                            <div className={"tipColorName"}>{this.state.tipRightColorName}</div>
                            <SvgButton icon={"arrow-down"} disable={this.state.disableButtons} onClick={() => {
                                this.moveWheel(-1)
                            }} id={"move-down"}/>

                        </TipInfo>
                    </>);
                break;
        }
    }

    render() {

        let debug = "";//"mode: " + this.state.mode + " transition: " + this.state.transition + " source: " + this.state.transitionSource + " target: " + this.state.transitionTarget;

        if (this.state.preload === 100 && !this.state.resizeInProgress) {
            return (
                <>
                    <span className={"debug"}>{debug}</span>
                    <BrowserView>
                        <div className={"desktop " + this.state.mode}>
                            {this.renderDesktop()}
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className={"mobile " + this.state.mode}>
                            {this.props.isLandscape && <h1> This is rendered only on mobile </h1>}
                            {!this.props.isLandscape &&
                            this.renderMobile()
                            }
                        </div>

                    </MobileView>

                </>
            );
        } else {
            return (<div className={"preloader"} style={{width:"250px",height:"250px"}}>
                <CircularProgressbarWithChildren className={"progress"}
                                                 value={this.state.preload} text={`${this.state.preload}%`}
                                                 strokeWidth={10}
                                                 styles={buildStyles({
                                                     strokeLinecap: "butt",
                                                     pathColor: "white",
                                                     trailColor: "#4a4a4a",
                                                     textColor: "white"
                                                 })}
                >
                    <RadialSeparators
                        count={20}
                        style={{
                            background: "black",
                            width: "3px",
                            // This needs to be equal to props.strokeWidth
                            height: `${10}%`
                        }}
                    />
                </CircularProgressbarWithChildren>
            </div>);
        }

    }
}

App = withOrientationChange(App)

export default App;
