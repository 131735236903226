import * as React from "react";

function SvgIcoBrush(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" {...props}>
      <path
        className="ico-brush_svg__st0"
        d="M35.7 48.6V40c0-4-.3-7.9-1-11.8V28C33.4 19.2 28.5 6.3 27 2.3c-.1-.3-.4-.4-.7-.3l-.3.3c-1.5 4-6.4 16.8-7.7 25.7v.2c-.7 3.9-1 7.8-1 11.8v8.6H8.1V51h36.8v-2.4h-9.2z"
      />
    </svg>
  );
}

export default SvgIcoBrush;
