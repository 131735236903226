import * as React from "react";

function SvgIcoRound(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" {...props}>
      <path
        className="ico-round_svg__st0"
        d="M33.3 41.2v-6.7c0-4.5-.7-9-2-13.2l-1.8-5.9c-.4-1.4-1.6-2.9-3-2.9s-2.6 1.5-3 2.9l-1.8 5.9c-1.3 4.3-2 8.8-2 13.2v6.7H3.3v3.1h46.3v-3.1H33.3z"
      />
    </svg>
  );
}

export default SvgIcoRound;
