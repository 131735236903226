import React from "react";
import MountingComponent from "./MountingComponent";
import Button from "./Button";
import SvgButton from "./SvgButton";

class DesktopTipControl extends MountingComponent {

    _render(){

        let classNames = this.props.className;
        if (this.props.edit) {
            classNames+=' edit';
        }
            /*            'plug-left':  this.props.plugStateLeft==='plug',
                        'unplug-left': this.props.plugStateLeft==='unplug'*/

        return (<div className={classNames} style={this.getStyles()} onTransitionEnd={this.transitionEnd}>
            {this.props.orientation=='right' && <div className={"tip-info"}>
                <div className={'tip-center'}>
                    <div key={"tipcolor"} className={"tipcolor"}>{this.props.tipColor}</div>
                    <div key={"tipcolorname"} className={"tipcolorname"}>{this.props.tipColorName}</div>
                    <div className={"tipType"}>{this.props.tipName}</div>
                </div>

            </div>}
            {!this.props.edit && this.props.orientation=='left' &&
            <SvgButton icon={"config"} disable={this.props.disableButtons} onClick={this.props.editClick} className={"config-btn"}/>
            }
            {this.props.edit && this.props.orientation=='left' && <Button className={"large"}
                    disable={this.props.disableButtons}
                    onClick={this.props.doneClick}>DONE</Button>}
            {this.props.edit && <><SvgButton icon={"round"} disable={this.props.disableButtons} active={this.props.tip === 2}
                       onClick={() => {
                           this.props.tipChange(this.props.orientation, 2)
                       }} className={'tipSelect'} id={"tip-round"}/>
            <SvgButton icon={"chisel"} disable={this.props.disableButtons} active={this.props.tip === 1}
                       onClick={() => {
                           this.props.tipChange(this.props.orientation, 1)
                       }} className={'tipSelect'} id={"tip-chisel"}/>
            <SvgButton icon={"brush"} disable={this.props.disableButtons} active={this.props.tip === 0}
                       onClick={() => {
                           this.props.tipChange(this.props.orientation, 0)
                       }} className={'tipSelect'} id={"tip-brush"}/></>}
            {this.props.edit && this.props.orientation=='right' && <Button className={"large"}
                                                disable={this.props.disableButtons}
                                                onClick={this.props.doneClick}>DONE</Button>}
            {!this.props.edit && this.props.orientation=='right' &&
            <SvgButton icon={"config"} disable={this.props.disableButtons} onClick={this.props.editClick} className={"config-btn"}/>
            }
            {this.props.orientation=='left' && <div className={"tip-info"}>
                <div className={'tip-center'}>
                    <div key={"tipcolor"} className={"tipcolor"}>{this.props.tipColor}</div>
                    <div key={"tipcolorname"} className={"tipcolorname"}>{this.props.tipColorName}</div>
                    <div className={"tipType"}>{this.props.tipName}</div>
                </div>
            </div>}

        </div>);
    }
}

export default DesktopTipControl;