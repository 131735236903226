import * as React from "react";

function SvgIcoConfig(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.8 12.8" {...props}>
      <path
        className="ico-config_svg__st0"
        d="M5.4 2.299v-2.3H3.6v2.3H0v1.8h3.6v2.3h1.8v-2.3h18.4v-1.8H5.4zM20.2 6.399h-1.8v2.3H0v1.8h18.4v2.3h1.8v-2.3h3.6v-1.8h-3.6v-2.3z"
      />
    </svg>
  );
}

export default SvgIcoConfig;
