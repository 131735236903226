import * as React from "react";

function SvgIcoArrowUp(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.1 11" {...props}>
      <path d="M17.7 11L9.5 2.8 1.4 11 0 9.5 9.5 0l9.6 9.5z" />
    </svg>
  );
}

export default SvgIcoArrowUp;
